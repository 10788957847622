import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { getCsvDataTransactions } from '../../utils/paymentsServices';
import { periodReferences } from '../../utils/periodDate';
import { useDateRange } from '../../utils/useDateRange';
import { CleanFilterButton } from '../library/CompactClearFilterButton';

import {
  updateFilterOptions,
  resetPaginationData,
  updatePeriod,
  updateStatus,
  updateFlag,
  updateProduct,
  updateColumn,
} from '../../redux/slices/transactions';
import { RootState } from '../../redux/store';
import { ExportButton } from '../library/ExportButton';
import { Messages } from '../../@types/messages';
import 'moment/locale/pt-br';

moment.locale('pt-br');
// ----------------------------------------------------------------------

const statusReferences = {
  ALL: 'Todos',
  FAIL: 'Não efetivado',
  SUCCESS: 'Efetivado',
  CANCELED: 'Cancelado',
};

const optionsValueList = {
  ALL: 'Todos',
  ALL_ITEMS: 'ALL',
};

const periodOptions = Object.values(periodReferences);

const statusOptions = ['Todos', 'Efetivado', 'Cancelado', 'Não efetivado'];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formWarning: {
    marginLeft: theme.spacing(2),
    marginTop: '1em',
    fontSize: '0.85em',
    color: '#FF4842',
    minWidth: 120,
  },
}));

const columnsHeadersWithProductItem = [
  { id: 'date', label: 'Data', width: 170, align: 'left' },
  { id: 'client', label: 'Cliente', width: 250, align: 'right' },
  {
    id: 'value',
    label: 'Valor',
    width: 100,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'installments',
    label: 'Parcelas',
    width: 50,
    align: 'right',
  },
  {
    id: 'flag',
    label: 'Bandeira',
    width: 120,
    align: 'right',
  },
  {
    id: 'product',
    label: 'Produto',
    width: 70,
    align: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    width: 50,
    align: 'right',
  },
];

const columnsHeadersOriginal = [
  { id: 'date', label: 'Data', width: 170, align: 'left' },
  { id: 'client', label: 'Cliente', width: 250, align: 'right' },
  {
    id: 'value',
    label: 'Valor',
    width: 100,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'installments',
    label: 'Parcelas',
    width: 50,
    align: 'right',
  },
  {
    id: 'flag',
    label: 'Bandeira',
    width: 120,
    align: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    width: 50,
    align: 'right',
  },
];

export function TransactionsFormFilter() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { switchOptions, _order, period, status, flag, product, authorizersPlace, listProducts } = useSelector(
    (state: RootState) => state.transactions
  );

  const flagReferences = {
    ALL: 'Todas',
    ...authorizersPlace,
  };
  const getSwitches = () => Object.values(flagReferences);
  const { reset } = useSelector((state: RootState) => state.global);

  let initialState = {
    period,
    status,
    flag,
    product,
    client: '',
  };

  const [filter, setFilter] = useState(initialState);
  const [privateLabelProduct, setPrivateLabelProduct] = useState<any>();
  const [privateLabelItem, setPrivateLabelItem] = useState<any>('Todos');
  const [exportRequesting, setExportRequesting] = useState(false);
  const { valueFromDate, valueBeforeDate, periodDateRange, handleDateChange } = useDateRange();

  useEffect(() => {
    if (periodDateRange) {
      dispatch(updatePeriod(periodDateRange));
    }
  }, [periodDateRange, dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'period') {
      const periodValues = Object.values(periodReferences);
      const periodKeys = Object.keys(periodReferences);
      const foundIndexOf = periodValues.findIndex((item) => item === value);
      setFilter({ ...filter, [name]: periodKeys[foundIndexOf] });
      if (periodKeys[foundIndexOf] === 'BETWEEN_DATES') return;
      dispatch(updatePeriod(periodKeys[foundIndexOf]));
    } else if (name === 'status') {
      const statusValues = Object.values(statusReferences);
      const statusKeys = Object.keys(statusReferences);
      const foundIndexOf = statusValues.findIndex((item) => item === value);
      setFilter({ ...filter, [name]: statusKeys[foundIndexOf] });
      dispatch(updateStatus(statusKeys[foundIndexOf]));
    } else if (name === 'flag') {
      dispatch(updateColumn(columnsHeadersOriginal));
      const flagValues = Object.values(flagReferences);
      const flagKeys = Object.keys(flagReferences);
      const foundIndexOf = flagValues.findIndex((item) => item === value);
      const authorizerItem = switchOptions.find((item) => item.name === value);
      const isPrivateLabel = authorizerItem?.isPrivateLabel;
      setPrivateLabelProduct(isPrivateLabel ? listProducts : null);
      dispatch(updateProduct(optionsValueList.ALL_ITEMS));
      if (isPrivateLabel) {
        setPrivateLabelItem(optionsValueList.ALL_ITEMS);
        dispatch(updateColumn(columnsHeadersWithProductItem));
      }
      setFilter({ ...filter, [name]: flagKeys[foundIndexOf], product: optionsValueList.ALL_ITEMS });
      dispatch(updateFlag(flagKeys[foundIndexOf]));
    } else if (name === 'product') {
      const valueFormated = value === optionsValueList.ALL ? optionsValueList.ALL_ITEMS : value;
      setPrivateLabelItem(valueFormated);
      setFilter({ ...filter, [valueFormated]: value });
      dispatch(updateProduct(valueFormated));
    }
  };

  const handleCleanFilter = () => {
    initialState = {
      period: 'ALL',
      status: 'ALL',
      flag: 'ALL',
      product: '',
      client: '',
    };
    setFilter(initialState);
    dispatch(updateFilterOptions(initialState));
  };

  useEffect(() => {
    setPrivateLabelProduct(listProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProducts]);

  const renderList = (list) =>
    list.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(resetPaginationData(true));
  };

  const handleExport = async () => {
    setExportRequesting(true);
    const end = 1000;
    const start = 0;
    const sort = 'createdAt';
    try {
      const result = await getCsvDataTransactions({
        _end: end,
        _order,
        _sort: sort,
        _start: start,
        period,
        authorizer: flag,
        status,
      });
      setExportRequesting(false);
      return result;
    } catch (error: any) {
      enqueueSnackbar(Messages[error.message], {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      setExportRequesting(false);
      return undefined;
    }
  };

  useEffect(() => {
    if (reset === true) {
      setFilter(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    setFilter({ ...filter, status });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setFilter({ ...filter, flag });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  useEffect(() => {
    setFilter({ ...filter, product });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <Box
      sx={{ mb: 1, mt: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <form
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
        id="paymentsForm"
        onSubmit={(event) => handleSubmit(event)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="payments-period">Período</InputLabel>
            <Select
              labelId="period-label"
              id="period"
              name="period"
              value={periodReferences[filter.period]}
              onChange={(event) => handleChange(event)}
              label="Período"
            >
              {renderList(periodOptions)}
            </Select>
          </FormControl>

          {filter.period === 'BETWEEN_DATES' && (
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="De"
                    inputFormat="DD/MM/YYYY"
                    value={valueFromDate?.toDate() || null}
                    onChange={(newDate) => handleDateChange(newDate ? moment(newDate) : null, true)}
                    renderInput={(params) => <TextField {...params} sx={{ width: '170px' }} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Até"
                    inputFormat="DD/MM/YYYY"
                    value={valueBeforeDate?.toDate() || null}
                    onChange={(newDate) => handleDateChange(newDate ? moment(newDate) : null, false)}
                    renderInput={(params) => <TextField {...params} sx={{ width: '170px' }} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </>
          )}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="payments-status">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              name="status"
              value={statusReferences[filter.status]}
              onChange={(event) => handleChange(event)}
              label="Status"
            >
              {renderList(statusOptions)}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="payments-flag">Bandeira</InputLabel>
            <Select
              labelId="flag-label"
              id="flag"
              name="flag"
              value={flagReferences[filter.flag]}
              onChange={(event) => handleChange(event)}
              label="Bandeira"
            >
              {renderList([...getSwitches()])}
            </Select>
          </FormControl>
          {privateLabelProduct && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="payments-products">Produtos</InputLabel>
              <Select
                labelId="products-label"
                id="product"
                name="product"
                value={privateLabelItem === 'ALL' ? 'Todos' : privateLabelItem}
                onChange={(event) => handleChange(event)}
                label="Produto"
              >
                {renderList(['Todos', ...privateLabelProduct])}
              </Select>
            </FormControl>
          )}
          <CleanFilterButton onClick={handleCleanFilter} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            ...(privateLabelProduct && filter.period === 'BETWEEN_DATES' ? { marginLeft: 2 } : { marginRight: 2 }),
          }}
        >
          <ExportButton
            handleExport={handleExport}
            isRequesting={exportRequesting}
            name={Messages.TRANSACTION_FILE_NAME}
          />
        </Box>
      </form>
      <div className={classes.formWarning}>
        Atenção! As informações de data são baseadas no Fuso horário de Brasília, DF (GMT-3).
      </div>
    </Box>
  );
}
