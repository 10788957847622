import { useState } from 'react';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';

export const useDateRange = () => {
  const [valueFromDate, setValueFromDate] = useState<Moment | null>(moment('01/01/2018', 'DD/MM/YYYY'));
  const [valueBeforeDate, setValueBeforeDate] = useState<Moment | null>(moment());
  const [periodDateRange, setPeriodDateRange] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const handleDateChange = (newValue: Moment | null, isFromDate: boolean) => {
    let newPeriodDateRange = '';

    if (isFromDate) {
      if (newValue && valueBeforeDate && newValue.isAfter(valueBeforeDate)) {
        enqueueSnackbar('A data de início não pode ser posterior à data de término', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        return;
      }
      setValueFromDate(newValue);
      newPeriodDateRange = `${newValue ? newValue.format('YYYY-MM-DD') : ''}:${
        valueBeforeDate ? valueBeforeDate.format('YYYY-MM-DD') : ''
      }`;
    } else {
      if (newValue && valueFromDate && newValue.isBefore(valueFromDate)) {
        enqueueSnackbar('A data de término não pode ser anterior à data de início', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        return;
      }
      setValueBeforeDate(newValue);
      newPeriodDateRange = `${valueFromDate ? valueFromDate.format('YYYY-MM-DD') : ''}:${
        newValue ? newValue.format('YYYY-MM-DD') : ''
      }`;
    }
    setPeriodDateRange(newPeriodDateRange);
  };

  const setInitialDate = (afterDate: Moment | null) => {
    setValueFromDate(afterDate);
    if (afterDate && valueBeforeDate) {
      const newPeriodDateRange = `${afterDate.format('YYYY-MM-DD')}:${valueBeforeDate.format('YYYY-MM-DD')}`;
      setPeriodDateRange(newPeriodDateRange);
    }
  };

  return {
    valueFromDate,
    valueBeforeDate,
    periodDateRange,
    handleDateChange,
    setInitialDate,
  };
};
