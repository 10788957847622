import * as moment from 'moment-timezone';

export enum CrmTransactionsReportFilterPeriods {
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  ALL = 'ALL',
}

export type CrmTransactionsReportFilterPeriod = CrmTransactionsReportFilterPeriods | string;

export function periodGetRangedDate(period: CrmTransactionsReportFilterPeriod): {
  afterDate: moment.Moment;
  beforeDate?: moment.Moment;
} {
  const afterDate = moment.tz('America/Sao_Paulo').startOf('day');

  switch (period) {
    case CrmTransactionsReportFilterPeriods.THIS_WEEK:
      afterDate.startOf('week').add(1, 'day');
      break;
    case CrmTransactionsReportFilterPeriods.THIS_MONTH:
      afterDate.startOf('month');
      break;
    case CrmTransactionsReportFilterPeriods.LAST_7_DAYS:
      afterDate.subtract(6, 'days').startOf('day');
      break;
    case CrmTransactionsReportFilterPeriods.LAST_30_DAYS:
      afterDate.subtract(29, 'days').startOf('day');
      break;
    case CrmTransactionsReportFilterPeriods.LAST_3_MONTHS:
      afterDate.subtract(2, 'months').startOf('month');
      break;
    case CrmTransactionsReportFilterPeriods.ALL:
      afterDate.set({ year: 2017, month: 0, date: 1 }).startOf('day');
      break;
    case CrmTransactionsReportFilterPeriods.TODAY:
    default:
      break;
  }

  return { afterDate };
}

export const periodReferences = {
  TODAY: 'Hoje',
  THIS_WEEK: 'Nesta semana (a partir de segunda-feira)',
  THIS_MONTH: 'Neste mês (a partir do dia 1°)',
  LAST_7_DAYS: 'Nos últimos 7 dias',
  LAST_30_DAYS: 'Nos últimos 30 dias',
  LAST_3_MONTHS: 'Nos últimos 3 meses',
  BETWEEN_DATES: 'Entre Datas',
  ALL: 'Todo o período',
};
