/* eslint-disable no-console */
import { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import MaskedInput from 'react-text-mask';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { TextField as TextFieldDate } from '@mui/material';
import { CleanFilterButton } from '../library/CompactClearFilterButton';
import { getCsvDataIdentifications } from '../../utils/identificationsServices';
import { ExportButton } from '../library/ExportButton';
import { updateFilterOptions, resetPaginationData } from '../../redux/slices/identifications';
import { RootState } from '../../redux/store';

import { periodReferences } from '../../utils/periodDate';
import { useDateRange } from '../../utils/useDateRange';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const periodOptions = Object.values(periodReferences);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formButton: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function IdentificationsFilter() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [exportRequesting, setExportRequesting] = useState(false);
  const [cpfError, setCpfError] = useState('');
  const [cpf, setCpf] = useState('');

  const { reset } = useSelector((state: RootState) => state.global);

  const initialState = {
    period: 'ALL',
    cpf: '',
  };

  const [filter, setFilter] = useState(initialState);

  const isRangedDate = filter.period.split(':')[1];
  if (isRangedDate) {
    setFilter((state) => ({ ...state, period: 'BETWEEN_DATES' }));
  }

  const { valueFromDate, valueBeforeDate, periodDateRange, handleDateChange } = useDateRange();

  const validateCpf = (cpf: string) => {
    const isCPFValid = isValidCPF(cpf);
    if (isCPFValid === false) {
      setCpfError('CPF inválido');
    } else {
      setCpfError('');
    }
  };

  useEffect(() => {
    if (periodDateRange) {
      setFilter((state) => ({ ...state, period: 'BETWEEN_DATES' }));
    }
  }, [periodDateRange]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'period') {
      const periodValues = Object.values(periodReferences);
      const periodKeys = Object.keys(periodReferences);
      const foundIndexOf = periodValues.findIndex((item) => item === value);
      setFilter((state) => ({ ...state, [name]: periodKeys[foundIndexOf] }));
    } else if (name === 'cpf') {
      validateCpf(value);
      setCpf(value);
      const cleanCpf = value.match(/\d/g).join('');
      setFilter((state) => ({ ...state, cpf: cleanCpf }));
    }
  };

  const renderList = (list) =>
    list.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(resetPaginationData(true));
    if (filter.period === 'BETWEEN_DATES') filter.period = periodDateRange;
    dispatch(updateFilterOptions(filter, true));
  };

  const handleCleanFilter = () => {
    setFilter(initialState);
    setCpf('');
    dispatch(resetPaginationData(true));
    dispatch(updateFilterOptions(initialState, true));
  };

  const handleExport = async () => {
    setExportRequesting(true);
    const result = await getCsvDataIdentifications({ period: initialState.period });
    if (result) {
      setExportRequesting(false);
      return result;
    }
  };

  useEffect(() => {
    if (reset === true) setFilter(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Box sx={{ mb: 1, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <form
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        onSubmit={(event) => handleSubmit(event)}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="payments-period">Período</InputLabel>
          <Select
            labelId="period-label"
            id="period"
            name="period"
            value={periodReferences[filter.period]}
            onChange={(event) => handleChange(event)}
            label="Período"
          >
            {renderList(periodOptions)}
          </Select>
        </FormControl>
        {filter.period === 'BETWEEN_DATES' && (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="De"
                  inputFormat="DD/MM/YYYY"
                  value={valueFromDate?.toDate() || null}
                  onChange={(newDate) => handleDateChange(newDate ? moment(newDate) : null, true)}
                  renderInput={(params) => <TextFieldDate {...params} sx={{ width: '170px' }} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Até"
                  inputFormat="DD/MM/YYYY"
                  value={valueBeforeDate?.toDate() || null}
                  onChange={(newDate) => handleDateChange(newDate ? moment(newDate) : null, false)}
                  renderInput={(params) => <TextFieldDate {...params} sx={{ width: '170px' }} />}
                />
              </LocalizationProvider>
            </FormControl>
          </>
        )}

        <FormControl>
          <MaskedInput
            mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholder="___.___.___-__"
            id="cpf"
            name="cpf"
            className={classes.formControl}
            onChange={(event) => handleChange(event)}
            render={(ref: any, props: any) => (
              <TextField
                {...props}
                style={{ minWidth: '110px' }}
                required={false}
                value={cpf}
                inputRef={ref}
                label="CPF"
                error={!!cpfError}
                helperText={cpfError}
              />
            )}
          />
        </FormControl>
        <LoadingButton
          type="submit"
          color="primary"
          variant="outlined"
          size="medium"
          onClick={() => handleSubmit}
          startIcon={<FilterListIcon />}
          className={classes.formButton}
        >
          Filtrar
        </LoadingButton>
        <CleanFilterButton onClick={handleCleanFilter} />
      </form>
      <Box sx={{ display: 'flex', marginRight: 2 }}>
        <ExportButton name="Identificacoes" handleExport={handleExport} isRequesting={exportRequesting} />
      </Box>
    </Box>
  );
}
