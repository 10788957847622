import { LoadingButton } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';

export const CleanFilterButton = ({ onClick }) => (
  <LoadingButton
    type="button"
    onClick={onClick}
    color="error"
    variant="outlined"
    sx={{
      height: 32,
      padding: 0,
      minWidth: 40,
      marginRight: 4,
    }}
  >
    <ClearIcon sx={{ fontSize: 16 }} />
  </LoadingButton>
);
